<template>
  <div ref="page_box" :class="['page',weixin && 'nopadding']">
    <div ref="top_box" class="header_box" v-if="!weixin">
      <div style="position: relative;">
        <img src="../../assets/back_arrow.png" alt="" class="arrow" @click="goBack">
        测试结果
      </div>
    </div>
    <div class="progress">
      <canvas id="myCanvas" class="canvas"></canvas>
      <div class="score_box">
        <span class="score">{{ correct_rate }} <span>分</span></span>
        <div class="defen">得分</div>
        <div class="xian"></div>
        <div class="total">共{{ count }}题</div>
      </div>
    </div>
    <div class="both"></div>
    <div class="result_msg">
      <div class="info_item">
        <div class="info_item_top">我的排名</div>
        <div class="info_item_btm">第{{ myrank }}名</div>
      </div>
      <div class="info_item info_center">
        <div class="info_item_top">做题用时</div>
        <div class="info_item_btm">{{ time_take }}</div>
      </div>
      <div class="info_item">
        <div class="info_item_top">平均分</div>
        <div class="info_item_btm">{{ average_score }}分</div>
      </div>
    </div>
    <div v-show="isShow" class="option_box">
      <template v-for="(item,index) in list">
        <span :key="index+'结果'" class="option_item" :class="item.is_true==='1'||item.is_true===1 ? 'test_correct' : 'test_error'" @click="openAnalys(index,item.answer)">{{ index+1 }}</span>
      </template>
    </div>
    <div class="footer" v-if="weixin">
        <div class="shouye" @click="jumpHome"> <img class="homepage" :src="require('../../assets/home.png')" alt=""> <span>首页</span> </div>
        <div class="alljiexi" @click="tips">全部解析</div>
        <div class="errjiexi" @click="tips">错题解析</div>
    </div>
    <van-dialog v-model="isShowDialog" confirm-button-text="我知道了" show-confirm-button>
        <img class="van_dialog_img" :src="dialog_img_url"/>
        <p class="van_dialog_content">
            课程请在注考帮APP进行观看，如未下载，请在应用商店搜索"注考帮"下载后观看~
        </p>
    </van-dialog>
  </div>
</template>

<script>
import { getResultInfo } from '@/api/home'
import wx from "weixin-js-sdk"
export default {
  data() {
    return {
      result_id: sessionStorage.getItem('result_id')||'', // 126240,
      redeem_id: '12',
      uid: sessionStorage.getItem('uid') || '',
      percentage: 0,
      myrank: '',
      part_name: '',
      time_take: '',
      average_score: '',
      correct_rate: '',
      count: '',
      list: [],
      isShow: true,
      weixin:this.weixin,
      isShowDialog:false,
      dialog_img_url:require('../../assets/dialog_bg.png')
    }
  },
  created() {
    this.uid = this.$route.query.uid
    this.result_id = this.$route.query.result_id
    // if (this.$route.query.first) {
    //   this.isShow = false
    // }
    this.getResult()
  },
  mounted() {
    this.setiosTop()
  },
  beforeDestroy(){
    sessionStorage.removeItem('uid')
    sessionStorage.removeItem('result_id')
  },
  methods: {
    // 提示下载APP
    tips(){
      this.isShowDialog=true
    },
    //跳转首页
    jumpHome(){
      // wx.miniProgram.navigateTo({url:"/pages/mkds/mkds"})
      this.$router.replace({path:"/"})
    },
    setiosTop() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        if(this.weixin){
        return false
      }
        if (u.indexOf(' sms_1 ') !== -1) {
          this.$refs.top_box.style.paddingTop = 40 + 'px'
          this.$refs.page_box.style.paddingTop = 84 + 'px'
        } else {
          this.$refs.top_box.style.paddingTop = 20 + 'px'
          this.$refs.page_box.style.paddingTop = 64 + 'px'
        }
        window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
      }
    },
    getResult() {
      const info = {
        result_id: this.result_id,
        uid: this.uid,
        app_type: 1
      }
      console.log(info)
      getResultInfo(info).then(res => {
        const data = res.result
        this.myrank = data.myrank
        this.percentage = data.correct_rate
        this.part_name = data.part_name
        this.time_take = data.time_take ? data.time_take : '0'
        this.average_score = data.average_score
        this.correct_rate = data.correct_rate
        this.count = data.count
        this.list = data.list
        console.log(this.list)
        sessionStorage.setItem('testResult', JSON.stringify(this.list))
      }).then(res => {
        this.drawCanvas(380, 190)
      })
    },
    drawCanvas(canvasWidth, canvasHigth) {
      const canvas = document.getElementById('myCanvas')
      const ctx = canvas.getContext('2d')
      canvas.width = canvasWidth
      canvas.height = canvasHigth
      const cicleCenterX = canvasWidth / 2
      const cicleCenterY = canvasHigth * 11 / 20
      ctx.beginPath()
      ctx.arc(cicleCenterX, cicleCenterY, 95, 0.7 * Math.PI, 2.3 * Math.PI)
      ctx.lineCap = 'round'
      ctx.strokeStyle = '#eee'
      ctx.lineWidth = 15
      ctx.setLineDash([0])
      ctx.stroke()
      ctx.beginPath()
      const grd = ctx.createLinearGradient(300, 0, 0, 0)
      grd.addColorStop(0, '#ffa600')
      grd.addColorStop(1, '#ff0000')
      // 进度-0.8min
      var minNum = 0.7
      var maxNum = 2.3
      var overToggle = ((maxNum * 10) - (minNum * 10)) / 10
      var upNum = overToggle / 100
      if (this.percentage === undefined || this.percentage === 0) {
        ctx.arc(cicleCenterX, cicleCenterY, 95, 0.7 * Math.PI, (minNum + (upNum * undefined)) * Math.PI)
      } else {
        ctx.arc(cicleCenterX, cicleCenterY, 95, 0.7 * Math.PI, (minNum + (upNum * this.percentage)) * Math.PI)
      }
      ctx.lineCap = 'round'
      ctx.strokeStyle = grd
      ctx.lineWidth = 15
      ctx.setLineDash([0])
      ctx.stroke()
    },
    openAnalys(index,answer) {
      this.$router.push({
        path: 'parsingItem',
        query: {
          part_name: this.part_name,
          index: index,
          daan:answer
        }
      })
    },
    goBack() {
      if (sessionStorage.getItem('testInfo')) {
        sessionStorage.removeItem('testInfo')
      }
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
    padding-top: 89px;
    height: 100vh;
    box-sizing: border-box;
    background: #eee;
    .header_box{
      border-bottom: 1px solid #eee;
    }
}

.nopadding{
  padding-top: 0px;
}
.progress{
    width: 100vw;
    height: auto;
    background: #fff;
    padding: 0;
    position: relative;
    .canvas{
        margin-top: 80px;
        width: 100%;
        min-height: 360px;
        position: relative;
    }
    .score_box{
        width: 100%;
        // height: 200px;
        text-align: center;
        position: absolute;
        bottom: 30px;
        .score{
            font-size: 45PX;
            color:orangered;
            span{
                font-size: 30PX;
            }
        }
        .defen{
            color:#333;
            margin-top: 60px;
            // margin-bottom: 30px;
            font-size: 16PX;
        }
        .xian{
            width: 180px;
            height: 1px;
            background: #c3c3c3;
            margin: 10px auto ;
            margin-bottom: 0px;
        }
        .total{
            color:#a09e9e;
            margin-top: 10px;
            font-size: 14PX;
        }
    }

}
.both{
    height: 50px;
    width: 100%;
    background-color: #fff;
}
.result_msg{
    width: 100%;
    height: 150px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12PX;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    .info_item{
        height: 100%;
        width: 33%;
        text-align: center;
        padding-top: 43px;
        box-sizing: border-box;
        div{
            text-align: center;
            font-size: 12PX;
            color: #999;
        }
        .info_item_btm{
            color: orangered;
            margin-top: 20px;
        }
    }
    .info_center{
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
    }
}
.option_box{
    border-top: 20px solid #eee;
    width: 100%;
    box-sizing: border-box;
    // height: calc(100vh - 100px - 89px - 50px - 20px);
    overflow: scroll;
    padding: 50px 30px 110px 30px;
    // display: flex;
    // justify-content: space-around;
    // flex-wrap: wrap;
    .option_item{
      display: inline-block;
      margin: 20px 35px;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      text-align: center;
      line-height: 60px;
      background-color: #dcdcdc;
      color: #fff;
    }
    .test_correct{
      background: url('../../assets/true.png');
      background-size: 100%;
    }
    .test_error{
      background:url('../../assets/false.png');
      background-size: 100%;
    }
}

.footer {
  height: 60px;
  width: 100%;
  border-top: 1px solid #EEEEEE;
  padding: 20px 0 24px 0;
  color: #EE2E2E;
  position: fixed;
  bottom: 0;
  // display: flex;
  background: #fff;
  // justify-content: space-between;

  .shouye {
    width: 176px;
    height: 54px;
    text-align: center;
    border-right: 1px solid #EEEEEE;
    line-height: 54px;
    display: inline-block;
    font-size: 32px;
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 40px;
      bottom: 44px;
    }
    span {
      padding-left: 40px;
    }
  }

  .alljiexi{
    width: 270px;
    text-align: center;
    border-right: 1px solid #EEEEEE;
    line-height: 54px;
    display: inline-block;
    font-size: 32px;
  }

  .errjiexi {
    width: 298px;
    text-align: center;
    height: 54px;
    line-height: 54px;
    display: inline-block;
    font-size: 32px;
  }
}

/deep/ .van-dialog {
      width: 600px;
      border-radius: 8px;
  }

  .van_dialog_img {
      width: 100%;
      height: 130px;
  }

  .van_dialog_content {
      height: 68px;
      font-size: 24px;
      font-weight: 400;
      margin: 30px 22px 20px 22px;
      color: #666666;
      line-height: 34px;
  }

</style>
